define("pixeditor/templates/competence/skills/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yeLGVcmO",
    "block": "[[[10,0],[14,0,\"main-title skill-header\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui right floated menu\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"ui button icon item\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"close\"]]],null],[12],[10,\"i\"],[14,0,\"icon window close\"],[12],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n    Versions de \"],[1,[30,0,[\"skill\",\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"ui attached segment competence\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@list\"],[[30,0,[\"model\",\"sortedSkills\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"ui borderless bottom attached labelled icon menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"mayCreateSkill\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"ui button right item\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"newSkillVersion\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"plus square outline icon\"],[12],[13],[1,\" Nouvelle Version\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"on\",\"list/skills\",\"if\"]]",
    "moduleName": "pixeditor/templates/competence/skills/list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});