define("pixeditor/templates/competence/tubes/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jSDrvcj",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "pixeditor/templates/competence/tubes/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});