define("pixeditor/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+/dY5cws",
    "block": "[[[10,0],[14,0,\"ui page dimmer inverted active\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui loader\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "pixeditor/templates/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});