define("pixeditor/templates/area-management/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MyXVlywn",
    "block": "[[[10,0],[14,0,\"main-left area-management\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui main-title\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"ui left floated header\"],[12],[1,\"Nouveau domaine du pix + \"],[1,[30,0,[\"framework\",\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"area-management__details\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"area-management__data\"],[12],[1,\"\\n      \"],[10,\"form\"],[14,\"action\",\"\"],[14,0,\"ui form\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,\"data-test-area-title-input\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,0,[\"area\",\"titleFrFr\"]],true,\"Titre\"]],null],[1,\"\\n        \"],[10,0],[14,0,\"ui raised segment\"],[12],[1,\"\\n          \"],[10,\"i\"],[14,0,\"flag gb uk\"],[12],[13],[1,\"\\n          \"],[8,[39,0],null,[[\"@value\",\"@edition\",\"@label\"],[[30,0,[\"area\",\"titleEnUs\"]],true,\"Titre (en)\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ui vertical compact labeled icon menu area-management__menu\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,\"data-test-save-button\",\"\"],[24,0,\"ui button item important-action\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n          \"],[10,\"i\"],[14,0,\"save icon\"],[12],[13],[1,\"\\n          Enregistrer\\n        \"],[13],[1,\"\\n        \"],[11,\"button\"],[24,\"data-test-cancel-button\",\"\"],[24,0,\"ui button item\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"cancelEdit\"]]],null],[12],[1,\"\\n          \"],[10,\"i\"],[14,0,\"ban icon\"],[12],[13],[1,\"\\n          Annuler\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"field/input\",\"on\"]]",
    "moduleName": "pixeditor/templates/area-management/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});