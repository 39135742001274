define("pixeditor/templates/events-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/1KHoPws",
    "block": "[[[10,0],[14,0,\"main-left\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui main-title\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"ui left floated header\"],[12],[1,\"Journal\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ui right floated header\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"ui inverted basic button\"],[16,\"disabled\",[30,0,[\"queryStatus\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"queryMoreLogs\"]]],null],[12],[10,\"i\"],[14,0,\"icon plus\"],[12],[13],[1,\" Voir plus\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@list\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\",\"list/events-log\"]]",
    "moduleName": "pixeditor/templates/events-log.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});