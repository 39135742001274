define("pixeditor/templates/competence/themes/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HWNsPoPn",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "pixeditor/templates/competence/themes/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});