define("pixeditor/components/target-profile/pdf-export", ["exports", "@glimmer/component", "jspdf", "jspdf-autotable", "canvg", "pdf-assets.js", "AmpleSoft-bold.js", "AmpleSoft-normal.js", "Roboto-normal.js", "Roboto-condensed.js", "Roboto-condensedBold.js", "Roboto-condensedLight.js"], function (_exports, _component, _jspdf, _jspdfAutotable, _canvg, _pdfAssets, _AmpleSoftBold, _AmpleSoftNormal, _RobotoNormal, _RobotoCondensed, _RobotoCondensedBold, _RobotoCondensedLight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="ui button" {{on "click" this.export}} type="button">
    <i class="pdf file icon"></i>PDF
  </button>
  {{#if this.displayTitleInput}}
    <PopIn::PdfEntries @validateAction={{this.generatePDF}} @close={{this.closeTitleInput}} />
  {{/if}}
  
  */
  {
    "id": "eu9O4jdB",
    "block": "[[[11,\"button\"],[24,0,\"ui button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"export\"]]],null],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"pdf file icon\"],[12],[13],[1,\"PDF\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"displayTitleInput\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@validateAction\",\"@close\"],[[30,0,[\"generatePDF\"]],[30,0,[\"closeTitleInput\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"on\",\"if\",\"pop-in/pdf-entries\"]]",
    "moduleName": "pixeditor/components/target-profile/pdf-export.hbs",
    "isStrictMode": false
  });

  const legalMentionByLanguage = {
    en: 'This is a working document, updated regularly. Its distribution is restricted and its use limited to Pix Orga members in the context of the implementation of the support of their users.',
    fr: 'Ceci est un document de travail. Il évolue régulièrement. Sa diffusion est restreinte et son usage limité aux utilisateurs de Pix Orga dans le cadre de la mise en oeuvre de l\'accompagnement de leurs publics.'
  };
  const firstPageTitleSize = 30;
  const firstPagePSize = 20;
  const firstPageLegalSize = 10.5;
  const areaTitleSize = 18;
  const competenceTitleSize = 12;
  const competenceTitleCellPadding = 3;
  const pSize = 7.4;
  const footerSize = 4;
  const margin = 15;
  const areaTitleHeight = 69;
  const fontColor = [65, 70, 87];
  const lightGrey = [250, 250, 250];
  const grey = [240, 240, 240];
  const colors = [[241, 161, 65], [87, 200, 132], [18, 163, 255], [255, 63, 148], [87, 77, 166], [56, 138, 255]];
  const areaGradient = [_pdfAssets.area1bg, _pdfAssets.area2bg, _pdfAssets.area3bg, _pdfAssets.area4bg, _pdfAssets.area5bg, _pdfAssets.area6bg];

  function createOffscreenCanvas(width, height) {
    let canvas;

    if (window.OffscreenCanvas) {
      canvas = new OffscreenCanvas(width, height);
    } else {
      canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      canvas['convertToBlob'] = async () => {
        return new Promise(resolve => {
          canvas.toBlob(resolve);
        });
      };
    }

    return canvas;
  }

  let TargetProfilePdfExportComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class TargetProfilePdfExportComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "displayTitleInput", _descriptor, this);
    }

    export() {
      this.displayTitleInput = true;
    }

    closeTitleInput() {
      this.displayTitleInput = false;
    }

    async generatePDF(title, language) {
      let y;
      const areas = this.args.model;
      const legalMention = legalMentionByLanguage[language];

      const pdfName = this._generatePdfName(title);

      const versionText = this._getVersionText(language);

      const pdf = new _jspdf.default('p', 'px', 'a4');
      const pdfWidth = pdf.internal.pageSize.width;
      const pdfHeight = pdf.internal.pageSize.height;
      const pageWidth = pdfWidth - margin * 2;
      const canvas = createOffscreenCanvas(pdfWidth, pdfHeight);
      const ctx = canvas.getContext('2d');
      const filteredArea = areas.find(area => area.competences.find(competence => competence.selectedProductionTubeCount > 0) !== undefined);
      const filter = filteredArea !== undefined;
      const v = await _canvg.default.fromString(ctx, _pdfAssets.firstPageBackground, _canvg.presets.offscreen());
      await v.render();
      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);
      pdf.addImage(pngUrl, 'PNG', margin / 2, margin / 2, pdfWidth - margin, pdfHeight - margin);
      pdf.setDrawColor(255, 255, 255);
      pdf.setLineWidth(margin);
      pdf.roundedRect(margin / 2, margin / 2, pdfWidth - margin, pdfHeight - margin, 15, 15, 'S');
      pdf.addImage(_pdfAssets.pixLogoWhite, 'PNG', (pdfWidth - 140) / 2, 120, 140, 105.7);
      pdf.setFont('AmpleSoft', 'bold');
      pdf.setFontSize(firstPageTitleSize);
      pdf.setTextColor('#fff');

      this._generateCenteredLongText(pdf, title, 350, 35, 300);

      pdf.setFontSize(firstPagePSize);
      pdf.setFont('AmpleSoft', 'bold');
      pdf.text(versionText, this._getCenteredX(pdf, versionText), 530);
      pdf.setFontSize(firstPageLegalSize);
      pdf.setFont('Roboto', 'normal');
      pdf.text(legalMention, 44, 580, {
        maxWidth: 360,
        align: 'justify'
      });
      pdf.addPage();

      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        const competences = filter ? area.sortedCompetences.filter(competence => competence.selectedProductionTubeCount > 0) : area.sortedCompetences;
        y = areaTitleHeight / 2 + 10;

        if (competences.length !== 0) {
          const areaName = this._getTranslatedAreaName(language, area);

          await this._buildRoundedGradientBackground(pdf, areaGradient[i], -15, -30, pdfWidth + 30, 49);
          pdf.setFont('AmpleSoft', 'bold');
          pdf.setFontSize(areaTitleSize);
          pdf.setTextColor('#fff');
          pdf.text(this._getCenteredX(pdf, areaName), areaTitleHeight / 2 - 10, areaName);
          competences.forEach(competence => {
            const competenceColor = colors[i];

            const competenceName = this._getTranslatedCompetenceName(language, competence);

            const tableHead = [[{
              content: `${competence.code} ${competenceName}`,
              colSpan: 3,
              rowSpan: 1,
              styles: {
                cellPadding: {
                  left: margin,
                  top: competenceTitleCellPadding,
                  right: margin,
                  bottom: competenceTitleCellPadding
                },
                fillColor: lightGrey,
                font: 'RobotoCondensed',
                fontStyle: 'bold',
                fontSize: competenceTitleSize,
                textColor: competenceColor,
                valign: 'middle'
              }
            }]];
            const themes = filter ? competence.sortedThemes.filter(theme => theme.hasSelectedProductionTube) : competence.sortedThemes.filter(theme => theme.hasProductionTubes);
            const tableBody = themes.reduce((values, theme) => {
              const tubes = filter ? theme.productionTubes.filter(tube => tube.selectedLevel) : theme.productionTubes;

              const buildCell = this._buildCell(theme, tubes, language);

              return [...values, ...buildCell];
            }, []);
            pdf.autoTable({
              startY: y,
              head: tableHead,
              body: tableBody,
              styles: {
                fillColor: lightGrey
              },
              theme: 'plain',
              pageBreak: 'avoid',
              rowPageBreak: 'avoid',
              margin: {
                top: margin,
                left: margin,
                right: margin,
                bottom: 0
              }
            });
            const newY = pdf.autoTable.previous.finalY; // Test if is a page break

            if (newY < y) {
              y = margin;
            } // Draw rounded corner


            pdf.setFillColor(...lightGrey);
            pdf.roundedRect(margin, y - 5, pageWidth, 10, 5, 5, 'F');
            pdf.setFillColor(...lightGrey);
            pdf.roundedRect(margin, newY - 5, pageWidth, 10, 5, 5, 'F'); // Reprint table to prevent text hide by roundedRect

            pdf.autoTable({
              startY: y,
              head: tableHead,
              body: tableBody,
              styles: {
                fillColor: lightGrey
              },
              theme: 'plain',
              pageBreak: 'avoid',
              rowPageBreak: 'avoid',
              margin: {
                top: margin,
                left: margin,
                right: margin,
                bottom: 0
              }
            }); // Draw separation between header and body

            const positionHead = pdf.autoTable.previous.head[0].height;
            pdf.setDrawColor(255, 255, 255);
            pdf.setLineWidth(2);
            pdf.line(0, y + positionHead, pdfWidth, y + positionHead); // Draw separation between theme

            let indexCell = 0;
            themes.forEach(theme => {
              const tubes = filter ? theme.productionTubes.filter(tube => tube.selectedLevel) : theme.productionTubes;
              indexCell += tubes.length;
              const positionCell = pdf.autoTable.previous.body[indexCell]?.cells[0];

              if (positionCell) {
                pdf.setDrawColor(255, 255, 255);
                pdf.setLineWidth(1);
                pdf.line(positionCell.x, positionCell.y + 2.5, pdfWidth - positionCell.x, positionCell.y + 2.5);
              }
            });
            y = 15 + pdf.autoTable.previous.finalY;
          });
          pdf.addPage();
        }
      }

      const pageCount = pdf.internal.getNumberOfPages();

      for (let i = 2; i <= pageCount; i++) {
        pdf.setTextColor(100, 100, 100);
        pdf.setFontSize(footerSize);
        pdf.setFont('Roboto', 'normal');
        pdf.setPage(i);
        pdf.text(margin, pdfHeight - 4, `${legalMention} - ${versionText}`);
      } // Remove last empty page


      pdf.deletePage(pageCount);
      pdf.save(`${pdfName}.pdf`);
    }

    _buildCell(theme, tubes, language) {
      const rowSpan = tubes.length;
      const cellPaddingTop = 5;
      const firstTube = tubes.shift();

      const {
        tubeName,
        tubeDescription
      } = this._getTranslatedTubeNameAndDescription(language, firstTube);

      const themeName = this._getTranslatedThemeName(language, theme);

      const firstCell = [{
        content: themeName,
        rowSpan,
        styles: {
          cellPadding: {
            top: cellPaddingTop,
            right: 5,
            bottom: 1,
            left: margin
          },
          cellWidth: 80,
          valign: 'middle',
          font: 'RobotoCondensed',
          fontStyle: 'bold',
          fontSize: pSize + 1,
          textColor: fontColor,
          fillColor: lightGrey
        }
      }, {
        content: tubeName,
        styles: {
          cellPadding: {
            top: cellPaddingTop,
            right: 5,
            bottom: 1,
            left: 1
          },
          cellWidth: 100,
          valign: 'middle',
          font: 'RobotoCondensed',
          fontStyle: 'normal',
          fontSize: pSize,
          textColor: fontColor,
          fillColor: lightGrey
        }
      }, {
        content: tubeDescription,
        styles: {
          cellPadding: {
            top: cellPaddingTop,
            right: 5,
            bottom: 1,
            left: 1
          },
          fontSize: pSize,
          valign: 'middle',
          font: 'RobotoCondensed',
          fontStyle: 'light',
          textColor: fontColor,
          fillColor: lightGrey
        }
      }];
      return tubes.reduce((values, tube, index) => {
        const fillColor = index % 2 === 0 ? grey : lightGrey;

        const {
          tubeName,
          tubeDescription
        } = this._getTranslatedTubeNameAndDescription(language, tube);

        const cells = [{
          content: tubeName,
          styles: {
            cellPadding: {
              top: 1,
              right: 5,
              bottom: 1,
              left: 1
            },
            cellWidth: 100,
            valign: 'middle',
            font: 'RobotoCondensed',
            fontStyle: 'normal',
            fontSize: pSize,
            textColor: fontColor,
            fillColor
          }
        }, {
          content: tubeDescription,
          styles: {
            cellPadding: {
              top: 1,
              right: 5,
              bottom: 1,
              left: 1
            },
            fontSize: pSize,
            valign: 'middle',
            font: 'RobotoCondensed',
            fontStyle: 'light',
            textColor: fontColor,
            fillColor
          }
        }];
        values.push(cells);
        return values;
      }, [firstCell]);
    }

    _getVersionText(language) {
      const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      const versionTextByLanguage = {
        en: `Version ${new Date().toLocaleDateString('en', dateOptions)}`,
        fr: `Version du ${new Date().toLocaleDateString('fr', dateOptions)}`
      };
      return versionTextByLanguage[language];
    }

    _getTranslatedAreaName(language, area) {
      const areaNameKeys = {
        en: 'titleEnUs',
        fr: 'titleFrFr'
      };
      return this._getTranslatedField(areaNameKeys, language, area);
    }

    _getTranslatedCompetenceName(language, competence) {
      const competenceNameKeys = {
        en: 'titleEn',
        fr: 'title'
      };
      return this._getTranslatedField(competenceNameKeys, language, competence);
    }

    _getTranslatedThemeName(language, theme) {
      const themeNameKeys = {
        en: 'nameEnUs',
        fr: 'name'
      };
      return this._getTranslatedField(themeNameKeys, language, theme);
    }

    _getTranslatedTubeNameAndDescription(language, tube) {
      const tubeNameKeys = {
        en: 'practicalTitleEn',
        fr: 'practicalTitleFr'
      };
      const tubeDescriptionKeys = {
        en: 'practicalDescriptionEn',
        fr: 'practicalDescriptionFr'
      };

      const tubeName = this._getTranslatedField(tubeNameKeys, language, tube);

      const tubeDescription = this._getTranslatedField(tubeDescriptionKeys, language, tube);

      return {
        tubeName,
        tubeDescription
      };
    }

    _getTranslatedField(keys, language, model) {
      const modelKey = keys[language];
      return model[modelKey];
    }

    _getCenteredX(pdf, text) {
      const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      return (pdf.internal.pageSize.width - textWidth) / 2;
    }

    async _buildRoundedGradientBackground(pdf, svg, x, y, width, height) {
      const canvas = createOffscreenCanvas(width, height);
      const ctx = canvas.getContext('2d');
      const strokeWidth = 15;
      const radius = 15;
      const v = await _canvg.default.fromString(ctx, svg, _canvg.presets.offscreen());
      await v.render();
      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);
      pdf.addImage(pngUrl, 'PNG', x - strokeWidth / 2, y + strokeWidth / 2, width + strokeWidth, height + strokeWidth);
      pdf.setDrawColor(255, 255, 255);
      pdf.setLineWidth(strokeWidth);
      pdf.roundedRect(x + strokeWidth / 2, y + strokeWidth / 2, width - strokeWidth, height + strokeWidth, radius, radius, 'S');
    }

    _generatePdfName(title) {
      const id = `${Date.now()}`.slice(5, 9);
      const generateDate = `${new Date().toLocaleDateString('fr')}_${id}`;
      return `${title}-${generateDate}`;
    }

    _generateCenteredLongText(pdf, text, breakX, breakY, positionY) {
      const lines = pdf.splitTextToSize(text, breakX);
      lines.forEach(line => {
        pdf.text(line, this._getCenteredX(pdf, line), positionY);
        positionY += breakY;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "displayTitleInput", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "export", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "export"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTitleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeTitleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generatePDF", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "generatePDF"), _class.prototype)), _class));
  _exports.default = TargetProfilePdfExportComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TargetProfilePdfExportComponent);
});